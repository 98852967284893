import { Box, Button, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

import '@talentinc/gatsby-theme-ecom/../../../common-styles/braintree-overrides.css'
import { navigate } from 'gatsby'
import failedPaymentSVG from '../../images/failed-payment.svg'
import { useWindow } from '../../hooks/useWindow'
import { toast } from 'react-hot-toast'

export const FailedPaymentPage = () => {
  const theme = useTheme()
  const windowObject = useWindow()
  const [url, setUrl] = useState<URL | null>()
  const [userName, setUserName] = useState<string | null>()

  useEffect(() => {
    if(!windowObject) return
    setUrl(new URL(windowObject.location.toString()))
  }, [windowObject])

  useEffect(() => {
    if(!url) return
    setUserName(url.searchParams.get('name'))
  }, [url])


  const onTryAgainClick = () => {
    if(url) {
      navigate('/purchase/' + url.searchParams.get('plan'))
    } else {
      toast.error('There was an error while loading this page. Please contact support')
    }
  }

  return (
    <Box
    sx={{
      height: '100%',
      minHeight: '60vh',
      maxWidth: 1086,
      width: '95%',
      margin: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        maxWidth: '500px',
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
        },
      }}
    >
      <img src={failedPaymentSVG} />
      <Typography variant="h2">Something went wrong</Typography>

      <Typography variant="body1">
        {userName ? `${userName}, l` : 'L'}ooks like your payment didn't go through! Please try again or use a new method, and contact your bank if the problem occurs again.
      </Typography>

      <Button onClick={onTryAgainClick}>Try again</Button>
    </Box>
  </Box>
  )
}
